import React from "react";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./swiper_custom.css";

const About3 = ({ noWave, rtl, isMobile }) => {
  const contents = [
    {
      component: <Content1 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "예약 관리",
    },
    {
      component: <Content2 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "고객 관리",
    },
    {
      component: <Content3 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "예약금 관리",
    },
    {
      component: <Content4 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "매출 관리",
    },
    {
      component: <Content5 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "다양한 기기",
    },
  ];

  return (
    <section
      className={`about style-4`}
      style={{ display: "flex", justifyContent: "center", padding: "40px 0px" }}
    >
      <div
        className="content"
        style={{ padding: "0px", maxWidth: isMobile ? "94%" : "1280px" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "40px",
          }}
        >
          <h1
            style={{
              letterSpacing: "-1px",
              fontWeight: "bold",
            }}
          >
            <strong style={{ color: "#F14262" }}>핑프메이커</strong>는 이런
            서비스를 해요!
          </h1>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="swiper-custom-pagination3"></div>
          </div>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={!isMobile}
            loop={true}
            pagination={{
              clickable: true,
              el: ".swiper-custom-pagination3",
              renderBullet: function (index, className) {
                return `<span class="${className}">${contents[index].label}</span>`;
              },
            }}
            scrollbar={false}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1,
              },
            }}
          >
            {contents.map((content, index) => (
              <SwiperSlide key={index}>{content.component}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default About3;
