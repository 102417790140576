import React from "react";

const Content2 = ({ features, rtl, isMobile }) => {
  return (
    <div className="content trd-content" style={{ padding: "0" }}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/home/fp1-2.png" alt="" />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info">
              <div className="section-head style-4">
                <small
                  className="title_small"
                  style={{
                    letterSpacing: "-0.2px",
                  }}
                >
                  {"네일 트렌드는 핑프에서!"}
                </small>
                <h2
                  className="mb-30"
                  style={{ letterSpacing: isMobile ? "-0.2px" : "0px" }}
                >
                  샵 찾기
                </h2>
              </div>
              <p
                className="text mb-40"
                style={{ letterSpacing: "-0.5px", fontSize: "20px" }}
              >
                핑프 앱은 우리 동네 네일샵을 검색하지 않아도 됩니다!
                <br /> 내 거리 기반으로 알아서 가까운 네일샵을 찾아줘요!
              </p>
              <ul>
                {features.map((feature, index) => (
                  <li
                    className={`d-flex align-items-center ${
                      feature.active ? "" : "op-4"
                    }`}
                    key={index}
                  >
                    <i className="bi bi-dot fs-2 me-2 lh-1 color-blue4"></i>
                    <h6 className="fw-bold">{feature.title}</h6>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content2;
