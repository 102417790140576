import React from "react";

const Content2 = ({ features, rtl, isMobile }) => {
  return (
    <div className="content trd-content" style={{ padding: "0" }}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/home/fm3-2.png" alt="" />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info">
              <div className="section-head style-4">
                <small
                  className="title_small"
                  style={{
                    letterSpacing: "-0.5px",
                  }}
                >
                  {"번거로운 일은 핑프가 할게요!"}
                </small>
                <h2
                  className="mb-30"
                  style={{ letterSpacing: isMobile ? "-0.2px" : "0px" }}
                >
                  고객 관리
                </h2>
              </div>
              <p
                className="text mb-40"
                style={{
                  letterSpacing: "-0.2px",
                  fontSize: "20px",
                }}
              >
                단골 고객, 신규 고객 관리하기 힘드셨죠? <br />
                한눈에 볼 수 있는 고객 정보로 시술 전 편하게 확인하고
                기록하세요!
              </p>
              <ul>
                {features.map((feature, index) => (
                  <li
                    className={`d-flex align-items-center ${
                      feature.active ? "" : "op-4"
                    }`}
                    key={index}
                  >
                    <i className="bi bi-dot fs-2 me-2 lh-1 color-blue4"></i>
                    <h6 className="fw-bold">{feature.title}</h6>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content2;
