import React, { useEffect, useRef, useState } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/AppNav";
import Testimonials from "components/home/Testimonials";
import Testimonials2 from "components/home/Testimonials2";
import Footer from "components/App/Footer";
import SuggestionBanner from "components/home/SuggestionBanner";
import Header2 from "components/home/Header2";
import About1 from "components/home/About1";
import About2 from "components/home/About2";
import About3 from "components/home/About3";
import About4 from "components/home/About4";
import ReasonSection from "components/home/ReasonSection";
import Project from "components/home/Project";
import EventPopup from "components/home/EventPopup";

const Home = () => {
  const navbarRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} section={"home"} isMobile={isMobile} />
      <Header2 isMobile={isMobile} />
      <main>
        <ReasonSection isMobile={isMobile} />
        <Project isMobile={isMobile} />
        <div id="finger_princess">
          <About1 isMobile={isMobile} />
          <About2 isMobile={isMobile} />
          <Testimonials isMobile={isMobile} />
        </div>
        <div id="fp_maker">
          <About3 isMobile={isMobile} />
          <About4 isMobile={isMobile} />
          <Testimonials2 isMobile={isMobile} />
        </div>
        <SuggestionBanner isMobile={isMobile} />
      </main>
      <Footer active={"home"} noWave={true} isMobile={isMobile} />
      <EventPopup />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>핑프-핑거프린세스</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default Home;
