import React from "react";

const Header2 = ({ isMobile }) => {
  return (
    <header
      className="style-5"
      data-scroll-index="0"
      style={{ padding: "60px 0px 0px 0px" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <h1
                style={{
                  fontSize: isMobile ? "40px" : "60px",
                  fontWeight: "bold",
                }}
              >
                <span>네일하는</span> 사람들의 <span>필수</span> 앱
              </h1>
              <p
                style={{
                  color: "#272F3E",
                  letterSpacing: "-0.5px",
                  fontSize: isMobile ? "16px" : "20px",
                }}
              >
                빠르고 간편한 네일샵 예약 및 디자인 분석을 통해
                <br />
                네일 트렌드를 이끌어나가는 네일 문화 플랫폼, 핑프-핑프메이커
              </p>
              <div
                className="d-flex align-items-center mt-50"
                style={{
                  justifyContent: "center",
                  position: "relative",
                  zIndex: "4",
                }}
              >
                <a
                  href="https://linktr.ee/wearefp.official"
                  rel="noreferrer"
                  className="btn rounded-pill fw-bold text-white"
                  target="_blank"
                  style={{ backgroundColor: "#181E2D", zoom: "1.2" }}
                >
                  <small>
                    <i className="fab fa-apple me-2 pe-2"></i>
                    <i className="fab fa-google-play me-2 pe-2"></i>앱 다운로드
                  </small>
                </a>
              </div>
              <div
                className="main-img"
                style={{
                  position: "relative",
                  zIndex: "1",
                  marginTop: isMobile ? "-50px" : "-200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/assets/img/home/hearder_main.png"
                  alt=""
                  style={{ maxWidth: "100vw", width: "100vw" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header2;
