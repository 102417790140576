import React from "react";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./swiper_custom.css";

const About4 = ({ noWave, rtl, isMobile }) => {
  const contents = [
    {
      component: <Content1 accordions={[]} rtl={rtl} isMobile={isMobile} />,
      label: "포트폴리오 관리",
    },
    {
      component: <Content2 accordions={[]} rtl={rtl} isMobile={isMobile} />,
      label: "푸시 알림",
    },
    {
      component: <Content3 accordions={[]} rtl={rtl} isMobile={isMobile} />,
      label: "매거진 및 에디터",
    },
    {
      component: <Content4 accordions={[]} rtl={rtl} isMobile={isMobile} />,
      label: "신규 고객 모집",
    },
  ];

  return (
    <section
      className={`about style-4`}
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "40px 0px",
        backgroundColor: "#F4F5F7",
      }}
    >
      <div
        className="content"
        style={{ padding: "0px", maxWidth: isMobile ? "94%" : "1280px" }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="swiper-custom-pagination4"></div>
          </div>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={!isMobile}
            loop={true}
            pagination={{
              clickable: true,
              el: ".swiper-custom-pagination4",
              renderBullet: function (index, className) {
                return `<span class="${className}">${contents[index].label}</span>`;
              },
            }}
            scrollbar={false}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1,
              },
            }}
          >
            {contents.map((content, index) => (
              <SwiperSlide key={index}>{content.component}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default About4;
