import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Project = ({ rtl, isMobile }) => {
  const [load, setLoad] = useState(false);
  const data = [
    {
      image: "/assets/img/home/effect1.jpg",
      title: "샵과 고객 간의 원활한 소통",
      type: ["SEO analysis"],
      text: "원장님과 고객에게 연락할 일이 생겼을 때, 연락처를 찾거나 고객 검색을 하느라 시간을 쓰지 마세요! 핑프 자체 메신저 기능으로 예약 건을 통해 바로바로 연락하세요.",
      tags: ["SEO Analysis", "Content Strategy"],
    },
    {
      image: "/assets/img/home/effect2.jpg",
      title: "요즘 핫한 앱",
      type: ["website design", "development"],
      text: "다양한 마케팅과 이벤트로 꾸준히 사용자 수가 증가하고 있어요. 한 달 사이 고객 수 2,000명 증가! (2024.7 기준)",
      tags: ["WordPress", "PHP", "HTML/CSS", "Figma"],
    },
    {
      image: "/assets/img/home/effect3.jpg",
      title: "홍보 비용 50-60% 절감",
      type: ["social media"],
      text: "네일샵 설문조사 결과, 연간 평균 100-200만 원을 홍보 비용으로 사용 중이라고 해요. 핑프는 올인원 서비스로 샵 홍보 비용 50~60% 절감하는 효과를 볼 수 있어요!",
      tags: ["Social", "Instagram", "Followers"],
    },
    {
      image: "/assets/img/home/effect4.jpg",
      title: "아무 걱정 없는 예약금 관리",
      type: ["website design", "development"],
      text: "원장님은 입금 확인할 필요없고, 네일러는 현금 걱정없이 카드로 예약금 간편하게 결제하세요! 핑프가 예약금을 관리해줄게요.",
      tags: ["WordPress", "PHP", "HTML/CSS", "Figma"],
    },
    {
      image: "/assets/img/home/effect5.jpg",
      title: "노쇼 횟수 감소",
      type: ["website design", "development"],
      text: "일일이 하루 전날 고객들에게 연락할 필요 없어요! 핑프가 알아서 예약금 및 날짜까지 자동 푸시 알람으로 고객을 관리해주니까!",
      tags: ["WordPress", "PHP", "HTML/CSS", "Figma"],
    },
    {
      image: "/assets/img/home/effect6.jpg",
      title: "네일 예약 A부터 Z까지 한 번에!",
      type: ["website design", "development"],
      text: "네일 디자인 검색, 내 취향 네일샵 예약, 결제, 네일샵과 연락까지 한 앱으로 완료!",
      tags: ["WordPress", "PHP", "HTML/CSS", "Figma"],
    },
  ];

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <section className="projects style-6" data-scroll-index="3">
      <div className="content section-padding">
        <div className="container">
          <div className="section-head mb-40 d-flex justify-content-center align-items-center style-6">
            {
              <h2
                className="mb-20"
                style={{
                  fontSize: isMobile ? "24px" : "38px",
                  letterSpacing: "0px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span>핑프</span>와&nbsp;<span>핑프메이커</span>를 사용해야만
                하는 이유
              </h2>
            }
          </div>
          <div className="slider-3items slider-style-6">
            {load && (
              <Swiper
                modules={[Navigation, Scrollbar, Autoplay]}
                className="swiper-container pb-70"
                dir={rtl ? "rtl" : "ltr"}
                slidesPerView={3}
                spaceBetween={0}
                speed={1000}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                mousewheel={false}
                keyboard={true}
                autoplay={{
                  delay: 30000,
                }}
                loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  480: {
                    slidesPerView: 1,
                  },
                  787: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {data.map((project, index) => (
                  <SwiperSlide key={index}>
                    <div className="project-card style-6">
                      <div
                        className="img"
                        style={{ height: isMobile ? "100%" : "100%" }}
                      >
                        <img
                          src={project.image}
                          alt=""
                          style={{
                            imageRendering: "auto",
                          }}
                        />
                      </div>
                      <div className="info" style={{ height: "260px" }}>
                        <h3
                          className="title"
                          style={{
                            paddingBottom: "20px",
                            fontSize: "22px",
                            color: "#6640f4",
                          }}
                        >
                          {project.title}
                        </h3>

                        <div
                          className="text"
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#181E2D",
                            letterSpacing: "-0.2px",
                          }}
                        >
                          {project.text}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <div className="swiper-pagination"></div>

            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project;
