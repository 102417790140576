import React from "react";

const Content4 = ({ features, rtl, isMobile }) => {
  return (
    <div className="content trd-content" style={{ padding: "0" }}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/home/fm3-4.png" alt="" />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info">
              <div className="section-head style-4">
                <small
                  className="title_small"
                  style={{
                    letterSpacing: "-0.5px",
                  }}
                >
                  {"번거로운 일은 핑프가 할게요!"}
                </small>
                <h2
                  className="mb-30"
                  style={{ letterSpacing: isMobile ? "-0.2px" : "0px" }}
                >
                  매출 관리
                </h2>
              </div>
              <p
                className="text mb-40"
                style={{
                  letterSpacing: "-0.2px",
                  fontSize: "20px",
                }}
              >
                열심히 일한 자! 매출을 확인하라!
                <br /> 나의 매출을 한 번에 금액으로 알려주고, 시술 완료까지
                깔끔하게 두 번의 터치로 완료 !
              </p>
              <ul>
                {features.map((feature, index) => (
                  <li
                    className={`d-flex align-items-center ${
                      feature.active ? "" : "op-4"
                    }`}
                    key={index}
                  >
                    <i className="bi bi-dot fs-2 me-2 lh-1 color-blue4"></i>
                    <h6 className="fw-bold">{feature.title}</h6>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content4;
