import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";

const Testimonials = ({ isMobile }) => {
  const testimonials = [
    {
      stars: 5,
      text: "카톡 문의를 별도로 드릴 필요 없이 버튼을 눌러서 옵션을 선택하고 예약하는 과정이 너무 편리했어요",
      author: {
        image: "/assets/img/testimonials/appreview/1.jpg",
        name: "유O진",
      },
    },
    {
      stars: 5,
      text: "네일 유목민+무계획 인간이라 매 번 네일하러 가는 길에 디자인을 찾곤 했는데 그래서 그런지 항상 2% 부족하고 아쉬웠고든요.. 그런 저에게 정말 단비같은 어플이에요ㅜㅜㅜㅜㅜ 더더 흥해서 많이 입점 됐으면 좋겠습니다.. 제발료….",
      author: {
        image: "/assets/img/testimonials/appreview/2.jpg",
        name: "다니929",
      },
    },
    {
      stars: 5,
      text: "예약금을 별도로 입금할 필요 없이 카드결제로 예약금을 결제하고 시술 후 카드 취소를 통해 예약금을 돌려받는 방식이 생각보다 간편해서 좋았어요",
      author: {
        image: "/assets/img/testimonials/appreview/3.jpg",
        name: "박O영",
      },
    },
    {
      stars: 5,
      text: "디자인들을 한 눈에 볼 수 있어서 너무 좋아요! 항상 어떤 네일 해야하나 어디로 가야하나 찾는 것 자체가 스트레스였는데 핑프에서 쉽게 찾아보고 예약도 바로 할 수 있더라구용! 유용합니다!",
      author: {
        image: "/assets/img/testimonials/appreview/4.jpg",
        name: "낸싱",
      },
    },
    {
      stars: 5,
      text: "손톱 깔끔하게 하고 다니는거 좋아하는데 주변엔 부담스러운 여성네일 위주 샵밖에 없어서 그냥 유목민이었거든요... 한번에 간편히 찾을 수 있어서 자주 쓸 것 같아요",
      author: {
        image: "/assets/img/testimonials/appreview/5.jpg",
        name: "alexchoi03",
      },
    },
    {
      stars: 5,
      text: "유행하는 인기 많은 디자인들이나 시럽네일, 파우더네일 등등 디자인 유형별로도 찾아볼 수 있어서 좋아요🥹💗 디자인 보면서 에약까지 한번에 되는 신세계… 만들어주셔서 감사합니다🙇‍♀️",
      author: {
        image: "/assets/img/testimonials/appreview/6.jpg",
        name: "_ciel_9",
      },
    },
    {
      stars: 5,
      text: "네일 연장해야하는데 어디 예약할까 고민하다가 이 어플 깔고 예약했는데!! 대만족… 회사앞 집앞 출장 여러 곳으로 다니는데 이제 이어플로 예약하기 훨씬 수월할거같네요💖",
      author: {
        image: "/assets/img/testimonials/appreview/7.jpg",
        name: "쭈선",
      },
    },
    {
      stars: 5,
      text: "네일샵 문의하기까지 한번에 되다니.. 찾던앱입니다",
      author: {
        image: "/assets/img/testimonials/appreview/8.jpg",
        name: "이딸램둘",
      },
    },
    {
      stars: 5,
      text: "UI도 편리하게 되어있고 네일샵 찾기도 정말 용이하네요!",
      author: {
        image: "/assets/img/testimonials/appreview/9.jpg",
        name: "ㄱㄴ020",
      },
    },
  ];

  return (
    <section
      className="testimonials style-8 section-padding"
      style={{ backgroundColor: "#F9FAFB" }}
    >
      <div className="container">
        <div className="section-head text-center style-8 mb-80 wow fadeInUp">
          <h3 style={{ fontWeight: "bold" }}> 실제 핑프 앱스토어 사용 후기</h3>
        </div>
        <div className="content">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={50}
            slidesPerView={3}
            navigation={false}
            pagination={false}
            scrollbar={false}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
              600: {
                slidesPerView: 2,
              },
              320: {
                slidesPerView: 1,
              },
            }}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="testi-card wow fadeInUp">
                  <div className="info">
                    <div className="stars">
                      {Array(testimonial.stars)
                        .fill()
                        .map((_, i) => (
                          <i className="fas fa-star" key={i}></i>
                        ))}
                    </div>
                    <p style={{ letterSpacing: "-0.5px" }}>
                      {" "}
                      “ {testimonial.text} ”{" "}
                    </p>
                    <img
                      src="/assets/img/icons/qout8.png"
                      alt=""
                      className="icon"
                    />
                  </div>
                  <div className="author mt-40">
                    <div className="img icon-60 rounded-circle overflow-hidden img-cover me-3 flex-shrink-0">
                      <img src={testimonial.author.image} alt="" />
                    </div>
                    <div className="inf">
                      <h6> {testimonial.author.name} </h6>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
