import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";

const Testimonials2 = ({ isMobile }) => {
  const testimonials = [
    {
      stars: 5,
      text: "시술 후 매출 등록이 편리해요",
      author: {
        image: "/assets/img/testimonials/shop.jpg",
        name: "신촌 - OO네일",
      },
    },
    {
      stars: 5,
      text: "핑프톡을 이용하면 고객과 연락하기 편리해요",
      author: {
        image: "/assets/img/testimonials/shop.jpg",
        name: "강남 - OO네일",
      },
    },
    {
      stars: 5,
      text: "네일 디자인 모아보기 기능과 거리순으로 볼 수 있는게 좋았어요",
      author: {
        image: "/assets/img/testimonials/shop.jpg",
        name: "왕십리 - OO네일",
      },
    },
    {
      stars: 5,
      text: "솔직히 반신반의 했는데 쓰다보니 생각보다 괜찮아서 계속 쓰고 있어요",
      author: {
        image: "/assets/img/testimonials/shop.jpg",
        name: "신촌 - OO네일",
      },
    },
    {
      stars: 5,
      text: "홍보가 필요한 1인샵에게 너무 좋은 앱이에요",
      author: {
        image: "/assets/img/testimonials/shop.jpg",
        name: "압구정 - OOO네일",
      },
    },
    {
      stars: 5,
      text: "피드백이 바로바로 수용 되는게 최고 장점인 플랫폼이에요!!",
      author: {
        image: "/assets/img/testimonials/shop.jpg",
        name: "네일OO",
      },
    },
  ];

  return (
    <section
      className="testimonials style-8 section-padding"
      style={{ backgroundColor: "#F9FAFB" }}
    >
      <div className="container">
        <div className="section-head text-center style-8 mb-80 wow fadeInUp">
          <h3 style={{ fontWeight: "bold" }}>
            {" "}
            원장님들의 핑프메이커 사용 후기
          </h3>
        </div>
        <div className="content">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={50}
            slidesPerView={3}
            navigation={false}
            pagination={false}
            scrollbar={false}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
              600: {
                slidesPerView: 2,
              },
              320: {
                slidesPerView: 1,
              },
            }}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="testi-card wow fadeInUp">
                  <div className="info">
                    <div className="stars">
                      {Array(testimonial.stars)
                        .fill()
                        .map((_, i) => (
                          <i className="fas fa-star" key={i}></i>
                        ))}
                    </div>
                    <p style={{ letterSpacing: "-0.5px" }}>
                      {" "}
                      “ {testimonial.text} ”{" "}
                    </p>
                    <img
                      src="/assets/img/icons/qout8.png"
                      alt=""
                      className="icon"
                    />
                  </div>
                  <div className="author mt-40">
                    <div className="img icon-60 rounded-circle overflow-hidden img-cover me-3 flex-shrink-0">
                      <img src={testimonial.author.image} alt="" />
                    </div>
                    <div className="inf">
                      <h6> {testimonial.author.name} </h6>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials2;
