import React, { useState, useEffect } from "react";

const EventPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const lastClosed = localStorage.getItem("popupClosed");
    if (
      !lastClosed ||
      new Date().getTime() - lastClosed > 24 * 60 * 60 * 1000
    ) {
      setIsVisible(true); // Show popup if it has been more than a day
    }
  }, []);

  const handleClose = () => {
    if (checked) {
      localStorage.setItem("popupClosed", new Date().getTime()); // Store current time
    }
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <img
          src="https://file.cafe24cos.com/banner-admin-live/upload/votrenaeil/59178cbb-58a7-4155-c1ac-72d266f10375.png"
          alt=""
          style={{ width: "400px", height: "400px" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
          }}
        >
          <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <span>오늘 하루동안 보지 않기</span>
          </div>
          <button
            onClick={handleClose}
            style={{
              border: "0px",
              backgroundColor: "transparent",
              textDecoration: "underline",
            }}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventPopup;
