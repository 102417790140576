import React from "react";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./swiper_custom.css";

const About1 = ({ noWave, rtl, isMobile }) => {
  const contents = [
    {
      component: <Content1 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "디자인 찾기",
    },
    {
      component: <Content2 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "샵 찾기",
    },
    {
      component: <Content3 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "나의 찜",
    },
    {
      component: <Content4 features={[]} rtl={rtl} isMobile={isMobile} />,
      label: "매거진 및 에디터",
    },
  ];

  return (
    <section
      className={`about style-4`}
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "40px 0px",
        scrollMarginTop: "100px",
      }}
    >
      <div
        className="content"
        style={{ padding: "0px", maxWidth: isMobile ? "94%" : "1280px" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "40px",
          }}
        >
          <h1
            style={{
              letterSpacing: "-1px",
              fontWeight: "bold",
            }}
          >
            <strong style={{ color: "#F14262" }}>핑프</strong>는 이런 서비스를
            해요!
          </h1>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="swiper-custom-pagination1"></div>
          </div>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={!isMobile}
            loop={true}
            pagination={{
              clickable: true,
              el: ".swiper-custom-pagination1",
              renderBullet: function (index, className) {
                return `<span class="${className}">${contents[index].label}</span>`;
              },
            }}
            scrollbar={false}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1,
              },
            }}
          >
            {contents.map((content, index) => (
              <SwiperSlide key={index}>{content.component}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default About1;
