import React from "react";

const ReasonSection = ({ isMobile }) => {
  return (
    <div
      className="section-head text-center style-4"
      style={{
        padding: "60px 0px",
        backgroundColor: "#e7f1ff",
        gap: "0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2
        className="mb-10"
        style={{
          fontSize: isMobile ? "24px" : "32px",
          letterSpacing: isMobile ? "-1px" : "0px",
          fontWeight: isMobile ? "400" : "700",
        }}
      >
        이런 고민해보지 않으셨어요?
      </h2>
      <p></p>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          className="d-flex align-items-center main-img"
          style={{
            justifyContent: "center",
            paddingTop: "20px",
            maxWidth: "1200px",
          }}
        >
          <img src="/assets/img/home/reason_main.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ReasonSection;
