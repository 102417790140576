import React from "react";

const Content3 = ({ accordions, rtl, isMobile }) => {
  return (
    <div className="content sec-content" style={{ padding: "0" }}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5 order-2 order-lg-0">
            <div className="info">
              <div className="section-head style-4">
                <small
                  className="title_small"
                  style={{
                    letterSpacing: "-0.5px",
                  }}
                >
                  {"여러분의 시간은 소중하니까"}
                </small>
                <h2
                  className="mb-30"
                  style={{ letterSpacing: isMobile ? "-0.2px" : "0px" }}
                >
                  예약금 카드 및 간편 결제
                </h2>
              </div>
              <p
                className="text mb-40"
                style={{
                  letterSpacing: "-0.2px",
                  fontSize: "20px",
                }}
              >
                지금까지 시술 날까지 묶여있던 나의 현금, 현금 결제만 가능했던
                네일샵, 이제는 안녕! 이제는 카드로 간편하게 결제하세요!
              </p>
              <div className="faq style-3 style-4">
                <div className="accordion" id="accordionExample">
                  {accordions.map((accordion, index) => (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header"
                        id={`heading${accordion.id}`}
                      >
                        <button
                          className={`accordion-button ${
                            index !== 0 ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${accordion.id}`}
                          aria-expanded="true"
                          aria-controls={`collapse${accordion.id}`}
                        >
                          {accordion.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${accordion.id}`}
                        className={`accordion-collapse collapse ${
                          index === 0 ? "show" : ""
                        }`}
                        aria-labelledby={`heading${accordion.id}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {accordion.content}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="img mb-30 mb-lg-0">
              <img src="/assets/img/home/fp2-3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content3;
