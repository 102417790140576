import React from "react";
import { Link } from "gatsby";

const SuggestionBanner = ({ isMobile }) => {
  return (
    <div
      className="section-head text-center style-4"
      style={{
        padding: "60px 0px",
        backgroundColor: "#E8EDF9",
        gap: "0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2 className="mb-10" style={{ fontSize: isMobile ? "16px" : "20px" }}>
        <span>{"홍보부터 고객 관리"}</span>
        {"까지 "}
        <span>{"트렌디한 올인원"}</span>
        {" 솔루션"}
      </h2>
      <h2
        className="mb-10"
        style={{
          fontSize: isMobile ? "28px" : "40px",
          fontWeight: "bold",
          letterSpacing: isMobile ? "-1px" : "0px",
        }}
      >
        {" 지금 바로 시작하세요💖"}
      </h2>
      <p></p>
      <div
        className="d-flex align-items-center"
        style={{
          justifyContent: "center",
          paddingTop: "20px",
          zoom: "1.2",
        }}
      >
        <Link
          to="https://forms.gle/b9edAEo8jtfHwL4SA"
          className="btn rounded-pill hover-pink sm-butn fw-bold"
          style={{
            backgroundColor: "#FD7F96",
            color: "#ffffff",
            border: "1px solid #ffffff",
          }}
        >
          <span>
            지금 바로 샵 등록하기 <i className="bi bi-arrow-right ms-1"></i>{" "}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default SuggestionBanner;
